<template>
  <div
    class="flex bg-gray-100 dark:bg-trueGray-800 flex-row-reverse min-h-screen"
  >
    <div class="flex flex-col w-0 flex-1">
      <TheHeader />
      <main class="flex-1 relative focus:outline-none">
        <NuxtErrorBoundary @error="onError">
          <slot />
          <template #error="{ clearError }">
            <div class="border-t-2 border-gray-100 h-full">
              <div class="bg-white p-3 sm:p-8 h-full">
                <ErrorPage :status-code="errorStatusCode" @clear="clearError" />
              </div>
            </div>
          </template>
        </NuxtErrorBoundary>
      </main>
    </div>

    <TheAppBanner />
    <TheAppUpdatePopup />
    <div
      v-if="notifications.length"
      class="fixed bottom-0 right-0 z-50 flex flex-col"
    >
      <NotificationsContainer />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNotifications } from '~/stores/notifications'

const { list: notifications } = useNotifications()
const cookieyesId = useRuntimeConfig().public.cookieyesId

const errorStatusCode = ref<number | undefined>(undefined)

const onError = (error: any) => {
  errorStatusCode.value = error.statusCode
}

!!cookieyesId &&
  process.server &&
  useHead({
    script: [
      {
        src: `https://cdn-cookieyes.com/client_data/${cookieyesId}/script.js`,
        id: 'cookieyes',
      },
    ],
  })
</script>
