<template>
  <div
    class="w-screen sm:w-96 flex flex-col items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
  >
    <TransitionGroup
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-for="item in list"
        :key="item.title"
        class="my-1 w-full flex justify-center sm:justify-end"
      >
        <NotificationsNotification :data="item" @close="close(item)" />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import NotificationsNotification from '~/components/notifications/NotificationsNotification.vue'
import {
  Notification as NotificationInterface,
  useNotifications,
} from '~/stores/notifications'

export default defineComponent({
  components: {
    NotificationsNotification,
  },
  setup() {
    const { list } = useNotifications()

    return {
      list,
    }
  },
  methods: {
    close(item: NotificationInterface) {
      const { removeNotification } = useNotifications()

      removeNotification(item)
    },
  },
})
</script>
