<template>
  <BaseModal fullscreen :is-open="isOpen" size="xl">
    <template #header>
      <div class="flex justify-between px-6 pt-6">
        <div class="text-xl font-bold">
          {{ $t('app.update_title') }}
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-6">
      <div>
        {{ $t('app.update_description') }}
      </div>
      <BaseButton @click="onUpdate">
        {{ $t('app.update_button') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { Device } from '@capacitor/device'

const { isApp } = useDetect()
const isOpen = ref(false)

async function checkUpdate() {
  try {
    const { isVirtual } = await Device.getInfo()

    if (!isApp || (isApp && isVirtual)) {
      return
    }
    const { isUpdateAvailable } = await useNative()

    const isNewVersion = await isUpdateAvailable()

    if (isNewVersion) {
      isOpen.value = true
    }
  } catch (error: any) {
    useLogError(error)
  }
}

onMounted(async () => {
  await checkUpdate()
})

async function onUpdate() {
  const { openStore } = await useNative()
  openStore()
}
</script>
