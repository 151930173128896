export const useNative = async () => {
  const module = await import('@capawesome/capacitor-app-update')
  const { AppUpdate, AppUpdateAvailability } = module
  const isUpdateAvailable = async () => {
    try {
      const result = await AppUpdate.getAppUpdateInfo()

      if (
        result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return true
      }
      return false
    } catch (error: any) {
      useLogError(error)
      return false
    }
  }

  return {
    openStore: AppUpdate.openAppStore,
    isUpdateAvailable,
  }
}
