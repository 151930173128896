<template>
  <div
    v-if="isShow"
    class="fixed bottom-0 left-0 right-0 py-4 px-4 bg-primary"
    style="box-shadow: 0px -14px 15px -6px rgba(0, 0, 0, 0.1); z-index: 999999"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="text-white mr-3 font-bold">{{ $t('app.title') }}</div>
        <a :href="path">
          <BaseButton theme="primary"> {{ $t('app.button') }} </BaseButton>
        </a>
      </div>
      <BaseButton size="xs" look="ghost" theme="dark" @click="onDismiss"
        ><BaseIcon name="outline_x"></BaseIcon
      ></BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const dismissCookie = useCookie('app-banner', { maxAge: 60 * 60 * 24 })
const { isApp } = useDetect()
const { isMobile } = useDevice()
const isShow = ref(isMobile && !isApp && !dismissCookie.value)
const path = computed(() => {
  return (
    'https://wnhub.io/app-link?link=https://wnhub.io' +
    useRouter().currentRoute.value.path
  )
})
function onDismiss() {
  dismissCookie.value = 'dismissed'
  isShow.value = false
}
</script>
