<template>
  <div
    :class="[data.clickHandler && 'cursor-pointer']"
    class="max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    @click="handleClick"
  >
    <div class="p-4">
      <div
        class="flex"
        :class="data.description ? 'items-start' : 'items-center'"
      >
        <div class="flex-shrink-0">
          <BaseIcon :name="data.icon" :class="proxyTheme" />
        </div>
        <div class="ml-3 w-0 flex-1">
          <p class="text-sm font-medium text-gray-900 break-words">
            {{ data.title }}
          </p>
          <p
            v-if="data.description"
            class="mt-1 text-sm text-gray-500 overflow-hidden overflow-ellipsis whitespace-pre-wrap"
          >
            {{ data.description }}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click.stop="$emit('close', data)"
          >
            <span class="sr-only"> Close </span>
            <BaseIcon
              name="outline_x"
              size="sm"
              class="text-gray-400 font-bold"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from '~/stores/notifications'

const themes: Record<string, string> = {
  primary: 'text-primary-400',
  success: 'text-green-400',
  destructive: 'text-red-400',
  info: 'text-blue-400',
}

export default defineComponent({
  props: {
    data: {
      type: Notification,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    proxyTheme() {
      return this.data.theme && themes[this.data.theme]
    },
  },
  methods: {
    handleClick() {
      if (this.data.clickHandler) {
        this.data.clickHandler()
        this.$emit('close')
      }
    },
  },
})
</script>
